<template>
  <div>
    <article class="card narrow">
      <div class="triangle-container">
        <div class="card-inner">
          <img
            :src="`https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg`"
            :srcset="`
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg 1x,
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 2x,
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 3x
            `"
            :alt="`${bio.name} headshot`"
          />
          <div class="flex">
            <p class="title">{{bio.name}}</p>
            <p v-html="bio.description"></p>
            <p class="small" v-html="bio.courses"></p>
          </div>
        </div>
        <span class="triangle"/>
      </div>
      <div class="tabs">
        <a :href="`https://www.instagram.com/${bio.ig}/`" class="social ig" target="_blank"><span class="screenreader">Visit Instagram</span></a>
        <a :href="`https://www.facebook.com/${bio.fb}`" class="social fb" target="_blank"><span class="screenreader">Visit Facebook</span></a>
        <a :href="`${bio.url}`" class="social web" target="_blank"><span class="screenreader">Visit Website</span></a>
        <a href="#" @click="emitVal(bio.modal)" class="btn" tabindex="0" role="button">View Profile</a>
      </div>
    </article>

    <article class="card wide">
      <div class="card-inner">
        <img
            :src="`https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg`"
            :srcset="`
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg 1x,
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 2x,
              https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 3x
            `"
            :alt="`${bio.name} headshot`"
          />
        <div class="flex">
          <p class="title">{{bio.name}}</p>
          <p v-html="bio.description"></p>
          <div class="flex-links">
            <a :href="`https://www.instagram.com/${bio.ig}/`" class="ig" target="_blank"><span class="screenreader">Visit Instagram</span></a>
            <a :href="`https://www.facebook.com/${bio.fb}`" class="fb" target="_blank"><span class="screenreader">Visit Facebook</span></a>
            <a :href="`${bio.url}`" class="web" target="_blank"><span class="screenreader">Visit Website</span></a>
          </div>
          <p class="small" v-html="bio.courses"></p>
          <a href="#" @click="emitVal(bio.modal)" class="btn" tabindex="0" role="button">View Profile</a>
        </div>
      </div>
      <span class="triangle"/>
    </article>
  </div>
</template>

<script>
  export default {
    name: "BioCard",

    props: {
      bio: Object
    },

    methods: {
      emitVal(modal) {
        this.$emit("clicked", modal)
      }
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.card {
  width: 330px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
  margin: 10px 8px 20px;
  background-color: var(--white);
  overflow: hidden;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 91px 0 0 91px;
  border-color: transparent transparent transparent var(--warm2);
  transition: 0.3s;
}

.card:hover .triangle {
  border-color: transparent transparent transparent var(--warm3);
}

.card-inner img {
  border: 1px solid #919191;
  margin-right: 12px;
  height: 154px;
  object-fit: cover;
}

.card-inner {
  display: flex;
  padding: 20px 17px;
  position: relative;
  z-index: 10;
}

.card-inner p.title {
  font-weight: 500;
  font-size: 18rem;
  line-height: 1.41;
  margin-bottom: 10px;
}

.card-inner p {
  font-weight: 400;
  font-size: 14rem;
  line-height: 1.41;
}

.card-inner p.small {
  font-weight: 400;
  font-size: 12rem;
  line-height: 1.41;
  margin-top: 20px;
}

.flex {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
}

.tabs img {
  height: 36px;
}

.tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs a:first-of-type {
  border-bottom-left-radius: 8px;
}

.ig,
.fb,
.web {
  background-color: #fff;
  height: 46px;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.ig {
  background-image: url('~@/assets/images/ig.svg');
}

.fb {
  background-image: url('~@/assets/images/fb.svg');
}

.web {
  background-image: url('~@/assets/images/web.svg');
}

a:hover.ig {
  background-image: url('~@/assets/images/ig-clr.svg');
}

a:hover.fb {
  background-image: url('~@/assets/images/fb-clr.svg');
}

a:hover.web {
  background-image: url('~@/assets/images/web-clr.svg');
}

.tabs a.social {
  width: 70px;
  height: 60px;
  border-top: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
}

.tabs a.btn {
  border: 2px solid #16a87c;
  font-style: normal;
  font-weight: 500;
  font-size: 14rem;
  line-height: 1.14;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #5e5f61;
  width: 100%;
  max-width: 124px;
  height: 60px;
  transition: 0.3s;
  background-color: var(--white);
  cursor: pointer;
  border-bottom-right-radius: 8px;
}

.tabs a.btn:hover {
  color: #fff;
  background-color: #16a87c;
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

@media only screen and (min-width: 930px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .card {
    width: 442px;
    height: 278px;
    padding: 25px 15px 25px 23px;
    position: relative;
    margin: 8px;
  }

  .card-inner {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .card-inner p.small {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .card-inner img {
    margin-right: 22px;
    height: 228px;
    object-fit: cover;
  }

  .triangle {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .flex a.btn {
    border: 2px solid #16a87c;
    font-style: normal;
    font-weight: 500;
    font-size: 14rem;
    line-height: 1.14;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: #5e5f61;
    width: 163px;
    height: 38px;
    transition: 0.3s;
    cursor: pointer;
  }

  .flex a.btn:hover {
    color: #fff;
    background-color: #16a87c;
  }

  .flex-links {
    display: flex;
    justify-content: flex-start;
  }

  .flex-links a {
    width: 35px;
  }

  .flex-links a:first-of-type {
    margin-left: -5px;
  }
}
</style>
