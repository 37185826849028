<template>
  <main class="board">
    <section class="intro">
      <img
        src="@/assets/images/banner-advisoryboard@1x.jpg"
        srcset="
          @/assets/images/banner-advisoryboard@1x.jpg 1x,
          @/assets/images/banner-advisoryboard@2x.jpg 2x,
          @/assets/images/banner-advisoryboard@2x.jpg 3x
        "
        alt="Close-up photo of a hand on a Reformer footbar"
        class="banner"
      />
      <div class="intro-content">
        <h1 class="h1 narrow">Firsthand Experience Guides Us Forward</h1>
        <h1 class="h2 wide">Firsthand Experience Guides Us Forward</h1>
        <hr>
        <p class="p2">We formed the Black Advisory Board of Master Instructors to gain community-based insights, context, guidance, and feedback. Our Advisory Board meets every 6 weeks to review programs, make recommendations and propose new initiatives, to increase Black participation in our Balanced Body community and in the wider Pilates industry. </p>
      </div>
    </section>

    <section class="primary">
      <div class="primary-inner">
        <div class="flex">
          <h3 class="h3">Balanced Body Black Advisory Board of Master Instructors</h3>
          <p class="p3">The Balanced Body Black Advisory Board of Master Instructors' input and guiding direction is essential to the creation and implementation of Balanced Body’s racial equity programs.  Each of our Advisory Board members has a unique background and perspective. Their experience as Black instructors in a predominantly white Pilates profession, enables them to help us identify and work towards breaking down racial barriers.</p>
        </div>
        <img
          src="@/assets/images/img-advisoryboard1@1x.jpg"
          srcset="
            @/assets/images/img-advisoryboard1@1x.jpg 1x,
            @/assets/images/img-advisoryboard1@2x.jpg 2x,
            @/assets/images/img-advisoryboard1@3x.jpg 3x
          "
          alt="Instructor with student using Cadillac"
        />
      </div>
      <span class="triangle" />
    </section>

    <section class="bios">
      <span class="bios-triangle" />
      <div class="bios-inner">
        <bio-card
          v-for="(bio, index) in bios"
          :key="index"
          @clicked="getModalNumber"
          :bio=bio
        />
      </div>
    </section>

    <section class="quote">
      <div class="quote-inner">
        <img src="@/assets/images/quotes.svg" alt="Quotation marks" />
        <div class="flex">
          <p class="callout">Every great dream begins with a dreamer. Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world.</p>
          <hr>
          <h5 class="h5">Harriet Tubman</h5>
        </div>
      </div>
    </section>

    <app-modal
      v-for="(bio, index) in bios"
      :key="index"
      :ref="'modal' + (index + 1)"
    >
      <template v-slot:header />
      <template v-slot:body>
        <bio-card-modal
          :bio=bio
        />
      </template>
      <template v-slot:footer />
    </app-modal>

  </main>
</template>

<script>
import BioCard from "@/components/BioCard.vue";
import BioCardModal from "@/components/BioCardModal.vue";
import AppModal from "@/components/AppModal.vue";

  export default {
    name: "Board",

    metaInfo: {
      title: "Balanced Body Black Advisory Board of Master Instructors",
      titleTemplate: "%s | Diversity in Pilates",
      meta: [
        {
          name: "description",
          content: "The Balanced Body Black Advisory Board of Master Instructors' input and guiding direction is essential to the creation and implementation of Balanced Body’s racial equity programs. Each of our Advisory Board members has a unique background and perspective. Their experience as Black instructors in a predominantly white Pilates profession, enables them to help us identify and work towards breaking down racial barriers."
        }
      ]
    },

    components: {
      BioCard,
      BioCardModal,
      AppModal
    },

    data() {
      return {
        bios: [
          {
            image: "img-labriece-ochsner",
            name: "LaBriece Ochsner",
            description: "Owner of LB Pilates Studios<br>Life Coach and Mentor<br>Biel, Switzerland and Online",
            ig: "labriecepilates",
            fb: "dani.labutter",
            url: "https://lb-pilates.ch",
            courses: "Pilates, Bodhi Suspension System",
            modal: "modal1",
            title: "Working with Men and Athletes",
            date: "Thursday, Nov 5, 2020<br/>Thursday, Nov 19, 2020",
            banner: "Nov 2020",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event1",
            content: "<p class='p1'>LaBriece Ochsner is a role model for healthy living, a life coach and international mentor, and master teacher of the Pilates Method. Balancing mothering three children and running her studio in person and online, LaBriece approaches teaching with a full heart, extraordinary insight, and precise attention to detail.</p><p class='p1'>LaBriece's passion is bringing Pilates back to men, cross-training athletes, working with teens and children, and mentoring new instructors in the Method. She owns LB Pilates Studios, the Balanced Body Authorized Training Center for Switzerland and is a Certified Pilates Instructor (NPCP).  LaBriece teaches retreats, conferences, and workshops worldwide, and Balanced Body Instructor Training in English and German, both online and in-person.</p>"
          },
          {
            image: "img-misty-lynne-cauthen",
            name: "Misty Lynne Cauthen",
            description: "Owner of Dragonfly Pilates<br>Trained Dancer<br>Pittsburgh, Pennsylvania",
            ig: "dragonflypilates",
            fb: "DragonflyPilates",
            url: "https://dragonflypilates.com/",
            courses: "Pilates, Barre, Bodhi Suspension System",
            modal: "modal2",
            title: "The Business of Teaching",
            date: "Thursday, Dec 3, 20202<br/>Thursday, Dec 17, 2020",
            banner: "Dec 2020",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event2",
            content: "<p class='p1'>For over 20 years, I’ve focused my energy on educating people of all ages and abilities about the power of movement to strengthen, balance, and heal their bodies. As a Balanced Body Master Instructor , I train teachers to share the gifts of Pilates, Bodhi Suspension System, and Balanced Body Barre so their students can thrive in their best bodies; and as a Business Coach, I’ve helped movement studio owners slay the dragons that inhibit their success.</p><p class='p1'>My goal as a teacher is to always lead my students and clients to the joy of movement through grace, humor, and hard work. And I love telling people what to do. Outside of Pilates, I am a comics geek and a lover of fight choreography in movies. My 15-year old son, Harrison, and partner, Glen, keep me honest, on my toes, and laughing… from my core, of course.</p>"
          },
          {
            image: "img-fabrice-lamego",
            name: "Fabrice Lamego",
            description: "Dancer and Choreographer<br>Owner: Pilates Pour Tous Studio<br>Lyon, France</p>",
            ig: "pilates_pour_tous_studio",
            fb: "Pilates-Pour-Tous-Studio-128236503940565",
            url: "https://pilatespourtousstudio.com",
            courses: "Pilates, MOTR, AI3D",
            modal: "modal3",
            title: "Creative Mat Work",
            date: "Thursday, Jan 7, 2021<br/>Thursday, Jan 21, 2021",
            banner: "Jan 2021",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event3",
            content: "<p class='p1'>A native of Guadeloupe, French West Indies, Fabrice Lamego had been dancing for eleven years when he began to study Pilates in New York during 1997. In 2002, he completed his Polestar® certification under the tutelage of Brent Anderson, and he started to teach by the end of that year. His approach to Pilates is enhanced by the consciousness of body and movement that he developed during his career as a dancer and choreographer. Since then he has used Pilates to be a better and stronger dancer, and to enhance his understanding of his body and prevent injuries.</p><p class='p1'>Fabrice is now a Balanced Body Master Instructor living and teaching in Lyon, France.</p><p class='p1'>In 2015 Fabrice enrolled in a two years post graduate mentorship program with Kathy Korey in Lausanne.</p><p class='p1'>His creative and vibrant approach to Pilates makes him a much sought-after instructor and international presenter.</p>"
          },
          {
            image: "img-sylvia-byrd-leitner",
            name: "Sylvia Byrd-Leitner, MFA",
            description: "Certified Pilates Instructor<br>Registered Yoga Teacher<br>Cherry Hill, New Jersey",
            ig: "pilatescorecenter",
            fb: "PilatesCoreCenter",
            url: "http://pilatescorecenter.com",
            courses: "Pilates, CoreAlign, MOTR, Barre, AI3D",
            modal: "modal4",
            title: "Teaching from the Heart",
            date: "Thursday, Feb 4, 2021<br/>Thursday, Feb 18, 2021",
            banner: "Feb 2021",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event4",
            content: "<p class='p1'>Sylvia Byrd-Leitner is an impassioned instructor who empowers her clients by getting them to recognize their own movement potential.  A perpetual student, she shares the knowledge she has accumulated throughout her life in the field of movement.  As an undergraduate, Sylvia worked as a physical therapist's aide and went on to earn her MFA in Dance/Movement from Temple University. She has performed and choreographed nationally and spent 5 years teaching at Rowan University before co-founding Pilates Core Center. She has been teaching Pilates for over 20 years and is a registered Yoga teacher. She finds genuine joy in movement and in bringing that freedom and joy of movement to every “body”.  Her keen eye and infectious enthusiasm help bring mind body awareness to her clients.</p><p class='p1'>Sylvia believes in moving the body with reverence and freedom…honoring all that we can do. “The Pilates Method teaches you to be in control of your body and not at its mercy“...Joseph Pilates</p>"
          },
          {
            image: "img-tonya-amos",
            name: "Tonya Amos",
            description: "Owner of Aspire Pilates<br>Dancer &amp; Activist<br>Concord &amp; Oakland, California",
            ig: "GrownWomenDanceCollective",
            fb: "GrownWomenDanceCollective",
            url: "https://grownwomendance.org/",
            courses: "Pilates, AI3D",
            modal: "modal5",
            title: "Elder Power: Keeping our Seniors Strong",
            date: "Thursday, Mar 4, 2021<br/>Thursday, Mar 18, 2021",
            banner: "Mar 2021",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event5",
            content: "<p class='p1'>Tonya Marie Amos received a B.A. in Anthropology from U.C. Berkeley, trained 4 years on scholarship at Alvin Ailey American Dance Center, and danced professionally for 15 years in New York. Introduced to Pilates over forty years ago as a young, injured dancer, she received her Comprehensive Pilates training in 2004 with Nora St. John and Naomi Leiserson, and since furthered her skills studying with 30+ Master Teachers. </p><p class='p1'>Owner of award-winning Aspire Pilates Center and Artistic Director of Grown Women Dance Collective, Tonya uniquely combines arts and wellness for social justice. Her stunning annual Juneteenth dance concert teaches and celebrates Black history. She is currently developing the Joyful Movement Pilates Life Skills Teacher Training program, which will help increase resilience, resistance, self-empowerment and joy in Black communities. She brings inspiring and healing experiences to communities that traditionally don’t have access, and is proud to help build cross-cultural and intergenerational bridges with her work.</p>"
          },
          {
            image: "img-tiza-wynn-riley",
            name: "Tiza Wynn Riley",
            description: "Owner of Nurture Works<br>BB Master instructor<br>Los Angeles, California",
            ig: "nurtureworks",
            fb: "nurtureworkshealing",
            url: "https://nurtureworkshealing.com/",
            courses: "Pilates, CoreAlign",
            modal: "modal6",
            title: "It's all about the Details",
            date: "Thursday, Apr 1, 2021<br/>Thursday, Apr 15, 2021",
            banner: "Apr 2021",
            zoom: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
            color: "event6",
            content: "<p class='p1'>Tiza Wynn Riley brings 20 years of experience, a meticulous eye for form, and a passion for movement and the human body to her wellness practice.  She was first introduced to Pilates at California Institute of the Arts where she received her BFA in Dance in 1993.  Tiza began teaching Pilates in 2000 after receiving her comprehensive Pilates certification through Long Beach Dance Conditioning, under the instruction of Marie-José Blom-Lawrence. Tiza’s curiosity about the human body and holistic health led her to also attain certification in CranioSacral Therapy and Massage Therapy.</p><p>Tiza became a Balanced Body faculty member in comprehensive Pilates and CoreAlign®, and also a RedCord Active Pro Specialist and Educator.</p><p class='p1'>An instructor at Core Conditioning in Los Angeles, Tiza works with clients of all abilities , and excels at perceiving her clients’ and draws her dance and bodywork trainings to help clients achieve optimum health and wellness.</p>"
          },
          {
            image: "img-norma-gray",
            name: "Norma Gray",
            description: "Certified Pilates Instructor<br>Trained Therapeutic Healer<br>Riyadh, Saudi Arabia",
            ig: "normagray.pilates",
            fb: "almanahilcenter",
            url: "http://www.almanahil.com.sa/",
            courses: "Pilates, Bodhi, MOTR",
            modal: "modal7",
            title: "",
            date: "",
            banner: "",
            zoom: "",
            color: "",
            content: "<p class='p1'>Norma Gray is a fully qualified Pilates teacher with more than 20 years’ fitness industry experience. Originating from the UK, Norma has worked in Italy, Switzerland, Asia and the Middle East and has been based in Riyadh, Saudi Arabia since 2010. Through a dedication to Joseph Pilates’ concept of Contrology combined with an invigorating and therapeutic teaching style, study has led her to work in areas such as serious spinal conditions, pregnancy, breast cancer recovery, menopause and osteoporosis.</p><p class='p1'>Norma is also a certified Yoga and Aerial Yoga teacher. She finds these practices enhance her prescriptive teaching, creative choreography and understanding of the moving body. Her goals are to continue sharing her knowledge of Pilates with others, integrating and encouraging other health, fitness and movement disciplines for improved wellness. Her greatest reward comes from seeing the positive life changes in the people she has worked with - physically, mindfully and emotionally.</p>"
          },
        ]
      }
    },

    methods: {
      getModalNumber(modal) {
        return eval(`this.$refs.${modal}[0].openModal()`);
      }
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.intro {
  margin-bottom: 50px;
}

.banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.intro-content {
  padding: 35px 34px 35px 32px;
}

.intro hr {
  border: none;
  border-top: 4px dotted #9EB675;
  margin: 40px 0 20px;
  width: 158px;
}

.primary {
  padding: 65px 32px;
  position: relative;
  background-color: var(--warm3);
}

.primary .h3 {
  color: var(--green2);
}

.primary .p3 {
  color: var(--white);
  margin: 15px 0 30px;
}

.primary img {
  width: 100%;
  border-radius: 8px;
}

.primary-inner {
  position: relative;
  z-index: 10;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: max(268px, 35vw) 0 0 max(324px, 45vw);
  border-color: transparent transparent transparent var(--warm4);
  position: absolute;
  bottom: 0;
  left: 0;
}

.bios {
  background-color: var(--green4);
  padding: 55px 0 0;
  position: relative;
  overflow: hidden;
}

.bios-inner {
  position: relative;
  z-index: 10;
  max-width: 1000px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
}

.bios-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 max(324px, 70vw) max(268px, 60vw) 0;
  border-color: transparent #cfe0b2 transparent transparent ;
  position: absolute;
  top: 0;
  right: 0;
}

.quote {
  background-color: var(--green4);
  padding: 65px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.quote-inner {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.quote .flex {
  max-width: 700px;
}

.quote img {
  margin: 6px 10px 0 0;
}

.quote hr {
  border: none;
  border-top: 4px dotted #fff;
  margin: 25px 0 16px;
  width: 70px;
}

::v-deep .p1 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1052px;
    margin: 0 auto 50px;
    padding: 0 min(calc(1084px - 100vw), 32px);
  }

  hr {
    margin: 50px 0 20px;
  }

  .intro-content {
    order: 1;
    width: 41%;
    padding: 0;
  }

  .banner {
    order: 2;
    width: 57%;
    max-width: 607px;
    height: 550px;
  }

  .primary {
    overflow: hidden;
    padding: 96px min(calc(1084px - 100vw), 32px);
  }

  .primary-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .primary-inner img {
    order: 1;
    max-width: 277px;
    margin-right: 30px;
  }

  .primary-inner .flex {
    order: 2;
    max-width: 500px;
  }

  .primary .p3 {
    margin-bottom: 0;
  }

  .bios {
    padding: 96px 0 0;
  }
}
</style>
